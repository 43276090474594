import './app.css';
import { DrawerFrame } from '@bestadapted/material-ui-web';
import { useViewport, withRoot } from '@bestadapted/utility-web';
import logo from './Logo.svg';
import { Link } from '@reach/router';

const App = () => {
    const [viewport] = useViewport();
    return (
        <DrawerFrame
            groups={undefined /* viewport.isPhone ? groupList : undefined */}
            content="full-height"
            showMenu={false}
            type="dismissible"
            className="alternative"
            /* actionItems={[...menuItem, ...actionItems]} */
            renderLogo={() => <Logo />}
            adapter="fixed">
            <div style={{ height: 'calc(100vh-30px)' }}></div>
        </DrawerFrame>
    );
};

export default App;

const groupList = [
    {
        value: 'Dashboard',
        icon: 'dashboard',
        action: () => {}
    },
    {
        value: 'My Property',
        icon: 'house',
        action: () => {}
    },
    null,
    {
        value: 'Tenants',
        icon: 'nature_people',
        action: () => {}
    },
    {
        value: 'My Appointments',
        icon: 'event_note',
        action: () => {}
    }
];

const Logo = () => {
    const [viewport] = useViewport();
    const isDesktop = viewport.isDesktop || viewport.isLaptop;

    return (
        <Link to={withRoot('/')} style={{ outline: 'none' }}>
            <picture>
                {/* <source media="(min-width: 650px)" srcSet={imageLogo} />
                <source media="(min-width: 465px)" srcSet={imageLogo} /> */}
                <img
                    src={logo}
                    title="renting automated"
                    alt="renting automated"
                    /* width={imageSize.width}
                    height={imageSize.height} */
                />
            </picture>
        </Link>
    );
};
